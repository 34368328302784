<template>
  <el-dialog
    :visible.sync="visibleLocal"
    title="AI Analysis"
    width="750px"
  >
    <div v-if="loading">Your request is being processed, please wait.</div>
    <div v-else>
      <div class="pb-4">
        <b>Impact</b>
        <div>
          {{ content.impact_summary }}
        </div>
      </div>
      <div v-if="content.outlet_profile" class="pb-4">
        <b>Outlet</b>
        <div>
          {{ content.outlet_profile }}
        </div>
      </div>
      <div class="pb-4">
        <b>Potential Actions</b>
        <li v-for="action in content.recommended_actions" :key="action">
          {{ action }}
        </li>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

export default {
  props: {
    streamId: {
      type: String,
      required: true
    },

    visible: {
      type: Boolean,
      default: false
    },

    mention: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      content: null
    }
  },

  computed: {
    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible')
  },

  watch: {
    visible (val) {
      if (val === true) {
        this.analyseMentionRequest()
      }
    }
  },

  methods: {
    async analyseMentionRequest () {
      this.loading = true

      await this.$api.post('/analyse-mention', {
        streamId: this.streamId,
        mentionId: this.mention.id
      })
        .then((response) => {
          this.content = response.data
          this.loading = false
        })
        .catch((error) => {
          this.visibleLocal = false
          this.$displayRequestError(error)
        })
    }
  }
}
</script>
