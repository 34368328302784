<template>
  <div>
    <!--      <el-checkbox-->
    <!--        v-model="showSaved"-->
    <!--        data-testid="ShowSavedMentionsCheckbox"-->
    <!--      >-->
    <!--        {{ $t( 'pages.streams.mentions.show_saved_mentions') }}-->
    <!--      </el-checkbox>-->
    <!--      <el-checkbox-->
    <!--        v-model="showHidden"-->
    <!--        data-testid="ShowHiddenMentionsCheckbox"-->
    <!--      >-->
    <!--        {{ $t('pages.streams.mentions.show_hidden_mentions') }}-->
    <!--      </el-checkbox>-->
    <stream-filters :start.sync="filters.start" :end.sync="filters.end" class="m-b-m">
      <template #primary>
        <div class="columns is-aligned-middle">
          <div class="column is-narrow" data-testid="selectedMentionsText">
            {{ $t('pages.streams.mentions.selected_mentions', { count: selectedMentions.length }) }}
          </div>
          <div class="column is-narrow">
            <send-mentions-to-bucket
              :selected-initially="selectedMentions.length"
              @select="showConfirmationDialog"
              @processing="handleMentionSelectionProcessing"
              @finish="handleMentionSelectionFinish"
            />
          </div>

          <div class="column is-narrow">
            <send-mentions-to-email
              :selected-mentions="selectedMentions"
              :stream-id="streamId"
              @finish="handleMentionSelectionFinish"
            />
          </div>
        </div>
      </template>
    </stream-filters>
    <load-more
      ref="mentions"
      v-loading="isSubmitting"
      :endpoint="mentionsUrl"
      :identifier="loadMoreId"
      :restore-state="!!$route.query.preserved"
      :focus-item="$route.query.mention_id"
      :before-focus="markMentionAsClippedInStore"
      :debounce="300"
      preserve-state
      :preserve-params.sync="filters"
      width="column is-12"
      placeholder="MentionPlaceholder"
      @load-more-clicked="trackLoadMoreClickIntent"
    >
      <template #item="{ item: mention }">
        <mention-item
          :mention="mention"
          :stream-id="streamId"
          :is-brand-stream="isBrandStream"
          @block-source="blockSource"
          @hide="handleHiddenMention(mention, $event)"
          @unhide="handleUnhiddenMention(mention, $event)"
          @unauthorized-save-mention="isUpgradeDialogVisible = true"
        >
          <template #header-actions>
            <el-checkbox-group v-model="selectedMentions" class="m-r-l">
              <el-checkbox
                class="no-label"
                :label="mention.id"
                data-testid="selectMentionCheckbox"
                @change="handleMentionSelection($event, mention)"
              />
            </el-checkbox-group>

            <el-tooltip
              v-if="isStaffMember"
              slot="reference"
              content="Analyze this mention"
              placement="top"
            >
              <v-button
                class="is-black is-narrow is-plain"
                @click="openAnalyzeMentionDialog(mention)"
              >
                <v-icon type="eye" size="lg" />
              </v-button>
            </el-tooltip>

            <el-tooltip
              slot="reference"
              :content="$t('pages.streams.mentions.report_mention_tooltip')"
              placement="top"
            >
              <v-button
                class="is-black is-narrow is-plain"
                data-testid="reportMentionTrigger"
                @click="openReportMentionDialog(mention)"
              >
                <v-icon type="notification" size="lg" />
              </v-button>
            </el-tooltip>
          </template>
        </mention-item>
      </template>
      <template #noItems>
        <i18n path="pages.streams.mentions.no_mentions_found">
          <restricted-button
            slot="action_link_to_click"
            :to="{ name: 'streams.edit', params:{ streamId } }"
            action-class="button is-unstyled has-text-decoration-underline"
            permission="stream.update"
          >
            {{ $t('pages.streams.mentions.edit_your_stream') }}
          </restricted-button>
        </i18n>
      </template>
    </load-more>
    <el-dialog
      :visible.sync="isUpgradeDialogVisible"
      class="el-dialog--no-title-bg is-fullscreen-mobile"
      width="750px"
      data-testid="MentionsUpgradeDialog"
    >
      <heading size="2">
        {{ $t('pages.streams.mentions.upgrade_dialog.heading') }}
      </heading>
      <div class="m-b-m">
        {{ $t('pages.streams.mentions.upgrade_dialog.subheading') }}
      </div>
      <div class="m-b-l" v-html="$t('pages.streams.mentions.upgrade_dialog.text')" />
      <router-link
        :to="{ name: 'cx.pricing' }"
        class="button is-primary"
      >
        {{ $t('pages.streams.mentions.upgrade_dialog.plans') }}
      </router-link>
    </el-dialog>

    <analyze-mention-dialog
      :visible.sync="isAnalyzeMentionDialogVisible"
      :mention.sync="selectedMention"
      :stream-id="streamId"
    />

    <report-mention-dialog
      :visible.sync="isReportMentionDialogVisible"
      :mention.sync="selectedMention"
      :stream-id="streamId"
    />

    <confirm-mentions-modal
      :selection="selectedMentionsObjects"
      :visible.sync="isConfirmSelectionDialogVisible"
      :action="actionObject"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

import MentionItem from '@/components/streams/MentionItem'
import StreamFilters from '@/components/streams/StreamFilters'
import SendMentionsToEmail from '@/components/streams/SendMentionsToEmail'
import ConfirmMentionsModal from '@/components/streams/ConfirmMentionsModal'
import SendMentionsToBucket from '@/components/streams/SendMentionsToBucket'

import { FETCH_BRAND_MENTIONS_ENDPOINT, FETCH_STREAM_MENTIONS_ENDPOINT } from '@hypefactors/shared/js/services/api/StreamsService'
import { getTimestampFromPredefinedSlug } from '@hypefactors/shared/js/vuex/utils/dateRange'
import AnalyzeMentionDialog from '../../components/streams/AnalyzeMentionDialog'
import ReportMentionDialog from '../../components/streams/ReportMentionDialog'

/**
 * @typedef {{ resolve: (function(): Promise), reject: (function(): Promise), payload: {}, title: String, validator: function(): Boolean }} MentionActionObject
 */

/**
 * The default action object passed from Mention Action Components
 * @return {MentionActionObject}
 */
const actionFactory = () => ({
  resolve: Promise.resolve,
  reject: Promise.reject,
  payload: {},
  validator: () => true
})

export default {
  name: 'Mentions',

  components: {
    StreamFilters,
    AnalyzeMentionDialog,
    ReportMentionDialog,
    ConfirmMentionsModal,
    SendMentionsToBucket,
    SendMentionsToEmail,
    MentionItem
  },

  props: {
    streamId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    isSubmitting: false,
    showSaved: false,
    showHidden: false,
    isUpgradeDialogVisible: false,
    isConfirmSelectionDialogVisible: false,
    isAnalyzeMentionDialogVisible: false,
    isReportMentionDialogVisible: false,
    selectedMention: null,
    selectedMentions: [],
    selectedMentionsObjects: [],
    actionObject: actionFactory(),
    filters: {
      start: getTimestampFromPredefinedSlug('two_weeks').getTime(),
      end: getTimestampFromPredefinedSlug().getTime()
    }
  }),

  computed: {
    ...mapGetters(['isStaffMember']),

    loadMoreId () {
      return `stream_${this.streamId}_mentions`
    },

    isBrandStream () {
      return this.streamId === 'brand_mentions'
    },

    /**
     * Returns the mentions URL
     * Different if the active stream is the Brand one
     * @returns {string}
     */
    mentionsUrl () {
      let url = ''
      if (this.isBrandStream) {
        url = FETCH_BRAND_MENTIONS_ENDPOINT(this.activeBrandId)
      } else {
        url = FETCH_STREAM_MENTIONS_ENDPOINT(this.streamId)
      }
      return buildUrlWithParams(url, {
        'show': [
          // this.showSaved ? 'clipped_by' : null,
          this.showHidden ? 'hidden_mentions' : null
        ],
        start: Math.round(this.filters.start / 1000),
        end: Math.round(this.filters.end / 1000)
      })
    }
  },

  watch: {
    streamId () {
      this.selectedMentions = []
      this.selectedMentionsObjects = []
    }
  },

  beforeDestroy () {
    clearTimeout(this.__mentionTimeoutId)
  },

  methods: {
    ...mapMutations('loadmore', {
      updateItemInStore: 'UPDATE_ITEM'
    }),

    selectDefaultStream (streams) {
      if (!this.activeStream) {
        this.activeStream = streams[0]
      }
    },

    /**
     * Blocks a mention's outlet
     * @param {HF_Mention} mention
     * @returns {Promise<void>}
     */
    async blockSource (mention) {
      try {
        await this.$confirm(this.$t('pages.streams.mentions.confirm_media_outlet_block'), {
          customClass: 'ConfirmMentionBlockDialog'
        })
        this.isSubmitting = true

        let stream = (await this.$api.get(`streams/${this.streamId}`)).data.data

        stream.blacklist.push({
          type: mention.type,
          value: mention.media_title_id,
          label: mention.media_title_name
        })

        await this.$api.put(`streams/${this.streamId}`, {
          blacklist: stream.blacklist,
          brand_id: this.activeBrandId
        })

        this.$notifyWithLink({
          title: this.$t('general.success'),
          to: { name: 'streams.edit', params: { streamId: this.streamId } },
          linkContent: this.$t('pages.streams.mentions.stream_edit'),
          message: this.$t('success.stream_media_outlet_blocked')
        })

        this.$refs.mentions && this.$refs.mentions.refresh()

        this.$ma.trackEvent({
          action: 'Block Media Intent',
          properties: {
            mediaTitleId: mention.media_title_id,
            mediaType: mention.type
          }
        })
      } catch (error) {
        this.$displayRequestError(error, this.$t('errors.stream_media_outlet_block'))
      } finally {
        this.isSubmitting = false
      }
    },

    /**
     * Marks the Mention as clipped in the Vuex Loadmore store
     * Called right before focusing an item to update its "is_clipped" status.
     * @param {string} identifier - The LoadMore Unique ID
     * @param {string} mentionId - The Mention ID
     */
    markMentionAsClippedInStore (identifier, mentionId) {
      this.updateItemInStore({
        id: identifier, itemId: mentionId, override: { is_clipped: true }
      })
    },

    /**
     * Sets a mention as hidden for the supplied level
     * Also updates the mention in the Vuex Loadmore store
     * @param {HF_Mention} mention - The Mention that is being augmented
     * @param {string} level - The level the mention was hidden for
     */
    handleHiddenMention (mention, level) {
      // if its already hidden, bail
      if (mention.hidden_by.includes(level)) return

      /* TODO DOBRI: Hack. The mention should not be augmented here, but we dont have access to it as its on the Load more component */
      mention.hidden_by.push(level)

      // update it in the Vuex store as well
      this.updateMentionHiddenByInStore(mention.id, mention.hidden_by)
      // Remove item after 5 seconds
      this.__mentionTimeoutId = setTimeout(() => {
        this.$refs.mentions && this.$refs.mentions.removeItem(mention)
      }, 5000)
    },

    /**
     * Sets the mention as not hidden for the supplied level
     * Also updates the mention in the Vuex Loadmore store
     * @param {HF_Mention} mention - The Mention that is being augmented
     * @param {string} level - The level the mention was hidden for
     */
    handleUnhiddenMention (mention, level) {
      // If its not hidden, bail
      if (!mention.hidden_by.includes(level)) return

      const index = mention.hidden_by.indexOf(level)

      /* TODO Dobri: Hack. It should not augment the Mention prop directly, but this is temporary */
      mention.hidden_by.splice(index, 1)

      // update it in the Vuex store as well
      this.updateMentionHiddenByInStore(mention.id, mention.hidden_by)
    },

    /**
     * Updates the Item's hidden_by property in the Vuex Loadmore store
     * @param {string} mentionId
     * @param {array} hiddenBy
     */
    updateMentionHiddenByInStore (mentionId, hiddenBy) {
      this.updateItemInStore({
        id: this.loadMoreId, itemId: mentionId, override: { hidden_by: hiddenBy }
      })
    },

    trackLoadMoreClickIntent () {
      this.$ma.trackEvent({ action: 'Load More Intent', properties: { type: 'mentions' } })
    },

    handleMentionSelection (isAdded, mention) {
      if (isAdded) {
        this.selectedMentionsObjects.push(mention)
      } else {
        const index = this.selectedMentionsObjects.findIndex(m => m.id === mention.id)
        if (index === -1) return
        this.selectedMentionsObjects.splice(index, 1)
      }
    },

    /**
     * Shows the confirmation dialog and
     * saves the action object for the "send" process
     * @param {MentionActionObject} actionObject
     */
    showConfirmationDialog (actionObject) {
      this.actionObject = actionObject
      this.isConfirmSelectionDialogVisible = true
    },

    /**
     * Closes the confirmation dialog when the batching finishes.
     */
    handleMentionSelectionProcessing () {
      this.isConfirmSelectionDialogVisible = false
    },

    /**
     * Finishes the mention selection process
     * Clears the selected mentions and closes the modal
     */
    handleMentionSelectionFinish () {
      this.selectedMentionsObjects = []
      this.selectedMentions = []
      this.actionObject = actionFactory()
    },

    openAnalyzeMentionDialog (mention) {
      this.selectedMention = mention
      this.isAnalyzeMentionDialogVisible = true
    },

    openReportMentionDialog (mention) {
      this.selectedMention = mention
      this.isReportMentionDialogVisible = true
    }
  }
}
</script>
