<template>
  <mention-item-base
    :class="{ 'Mention-item--clipped': mention.is_clipped, 'Mention-item--hidden': isHiddenForAnything }"
    :media-title-name="mention.media_title_name"
    :content-title="mention.content_title"
    :content-body-highlighted="mention.content_body_highlighted"
    :content-body="mention.content_body"
    :published-at="mention.published_at"
    :countries="mention.countries"
    :languages="mention.languages"
    :presence="mention.presence"
    :sentiment="mention.sentiment"
    :type="mention.type"
    :impressions="mention.impressions"
    :visits-pr-month="mention.visits_pr_month"
    :estimated-clicks="mention.estimated_clicks"
    :image="mention.thumbnail"
    :source="mention.source_link"
    :duration="mention.duration"
    :ad-likelihood="mention.ad_likelihood"
    :is-advertisement="mention.is_advertisement"
    class="Mention-item"
    data-testid="mentionItemCard"
  >
    <template #header-actions>
      <slot name="header-actions" />
      <el-tooltip
        slot="reference"
        :content="$t('components.streams.mention_item.block_outlet_title')"
        placement="top"
      >
        <restricted-button
          v-if="isBlockSourceVisible"
          data-testid="blockSource"
          permission="stream.update"
          action-class="button is-primary is-plain is-narrow"
          class="is-block m-r-s"
          @click.prevent="blockSource"
        >
          <v-icon type="block" size="lg" />
        </restricted-button>
      </el-tooltip>
    </template>
    <template #after-content>
      <br>

      <a
        v-if="mention.translation_url"
        :href="mention.translation_url"
        class="button is-primary is-small is-plain is-narrow mr-2"
        target="_blank"
      >
        <v-icon type="external-link" />
        <span class="ml-1">{{ translationLink }}</span>
      </a>
    </template>
    <template #actions>
      <div class="buttons is-aligned-end">
        <restricted-button
          :disabled="isSavingDisabled"
          :tooltip-text="mention.is_clipped ? 'components.streams.mention_item.already_saved' : undefined"
          :action-class="[{ 'is-dark': !mention.is_clipped}, { 'is-black': mention.is_clipped }, 'is-plain is-narrow is-mobile-plain button']"
          :loading="form.isLoading"
          permission="clipping.create"
          data-testid="saveMention"
          enable-when-not-authorized
          @authorized-click="handleUnauthorizedSaveMention"
          @click="saveMention"
        >
          <v-icon
            :type="mention.is_clipped ? 'check': 'download'"
            size="lg"
          />
          <div
            v-show="requiresSocialToken"
            class="requiresSocialToken icon is-round has-background-danger is-size-7"
          >
            !
          </div>
        </restricted-button>

        <!-- <mention-item-hide-button
          :mention="mention"
          :stream-id="streamId"
          :is-brand-stream="isBrandStream"
          :brand-id="activeBrandId"
          :organisation-id="firstOrganisation.id"
          class="is-block m-r-a"
          @hide="$emit('hide', $event)"
          @unhide="$emit('unhide', $event)"
        /> -->
      </div>
    </template>
  </mention-item-base>
</template>

<script>
import Form from '@/services/forms/Form'
import MentionItemBase from '@hypefactors/shared/js/components/streams/MentionItemBase'
// import MentionItemHideButton from '@/components/streams/MentionItemHideButton'
import SocialTokenManagerMixin from '@/components/social/SocialTokenManagerMixin'
import { UNCLIPPABLE_MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

export default {
  components: {
    MentionItemBase
    // MentionItemHideButton
  },

  mixins: [SocialTokenManagerMixin],

  props: {
    /** @type {HF_Mention} */
    mention: {
      type: Object,
      required: true
    },
    isBrandStream: {
      type: Boolean,
      default: false
    },
    streamId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    form: new Form({
      mention_id: { value: '' },
      type: { value: '' },
      brand: { value: '' },
      stream: { value: '' },
      action: { value: 'mention' } // note that this is a mention being saved to a clipping
    })
  }),

  computed: {
    /**
     * Checks whether this mention needs social authorization and
     * user has permission to authorize
     * @return {boolean}
     */
    requiresSocialToken () {
      return this.requiresSocialTokenForSomeNetworks(this.mention.type) && this.authorizeBool('clipping.create')
    },
    /**
     * Returns whether the blockSource button should be visible
     * Should not be visible if on BrandStream or mention is clipped
     * @return {boolean}
     */
    isBlockSourceVisible () {
      return !this.isBrandStream && this.mention.media_title_id
    },
    /**
     * Shows if the mention is hidden under some circumstances
     * @return {boolean}
     */
    isHiddenForAnything () {
      return this.mention.hidden_by.length > 0
    },
    isSavingDisabled () {
      return UNCLIPPABLE_MEDIA_TYPES.includes(this.mention.type) || this.mention.is_clipped
    },
    translationLink () {
      const locale = this.$t(`components.language_switcher.languages.${this.$i18n.locale}`)
      return this.$t('components.streams.mention_item.read_in_locale', { locale })
    }
  },

  mounted () {
    // merge the mention's data to the form
    this.form.merge({
      mention_id: this.mention.id,
      type: this.mention.type,
      brand: this.activeBrandId,
      stream: this.streamId
    })
  },

  methods: {
    async saveMention () {
      // check if a social token is required and request it if so.
      if (this.requiresSocialToken) {
        try {
          await this.$confirm(this.$t('components.streams.manage_medias.requires_authorization_confirm'), {
            confirmButtonText: this.$t('forms.ok'),
            cancelButtonText: this.$t('forms.cancel'),
            type: 'warning'
          })
          await this.requestSocialToken(this.mention.type)
        } catch (err) {
          return
        }
      }
      const redirectUrl = {
        name: this.$store.state.route.name,
        params: this.$store.state.route.params,
        query: { ...this.$store.state.route.query, mention_id: this.mention.id }
      }
      await this.$store.dispatch('clippings/add/setRedirect', redirectUrl)
      await this.$store.dispatch('clippings/add/prepareClippingData', this.form)
        .then(() => {
          this.$router.push({ name: 'performance.coverage.clipping.create' })
        })
        .catch(err => {
          // skip error reporting for duplicate clippings
          this.$displayRequestError(err, this.$t('errors.cannot_add_clipping'), 10000, [400])
        })
    },

    blockSource () {
      this.$emit('block-source', this.mention)
    },

    handleUnauthorizedSaveMention (hasAuthorization) {
      if (hasAuthorization) return
      this.$emit('unauthorized-save-mention')
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Mention-item {

  &--hidden {
    opacity: .3;
    transition: .15s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
